import React from "react";
// import App from "../App";
import { Navigate } from "react-router-dom";

import Pdf from "../pdf";
import AbstractPdf from "../abstractPdf";
import CategoryAbstractPdf from "../CategoryAbstractPdf";

const AppRoute = [
	// { path: "", element: <Navigate to="pdf" /> },
	{ path: "categoryabstractpdf", element: <CategoryAbstractPdf /> },
	{ path: "abstractpdf", element: <AbstractPdf /> },
	{ path: "/", element: <Pdf /> },
];

export default AppRoute;
