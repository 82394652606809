import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_URL;

const postWithoutAuth = async (body, endpoint) => {
	const headers = {
		"Content-Type": "application/json",
	};
	return await axios
		.post(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		})
		.catch((error) => {
			return error?.response?.data;
		});
};

const getWithoutAuth = async (body, endpoint) => {
	const headers = {
		"Content-Type": "application/json",
	};
	return await axios
		.get(`${server_url}/${endpoint}`, body, { headers })
		.then((response) => {
			if (response && response.data) {
				return response.data;
			}
		});
};

export const getWorkListById = async (workListId) => {
	const body = { workListId };
	const endpoint = `get-worklist-unauthorized`;
	return await postWithoutAuth(body, endpoint);
};

export const getUserAbstract = async (userId, designation, fYear, IA) => {
	const body = { userId, designation, fYear, IA };
	const endpoint = `get-user-abstract`;
	return await postWithoutAuth(body, endpoint);
};

export const getUsersAbove = async (data) => {
	const body = data;
	const endpoint = `getUsersAbove`;
	return await postWithoutAuth(body, endpoint);
};

export const getUserAbstractWithIA = async (userId, designation, IA, fYear) => {
	const body = { userId, designation, IA, fYear };
	const endpoint = `get-user-abstract-by-IA`;
	return await postWithoutAuth(body, endpoint);
};