import { useEffect, useState } from "react";
import { CategoryAbstractTableRow } from "./CategoryAbstractTableRow";
import QRCode from "react-qr-code";

export const CategoryAbstractTable = ({
	abstractData,
	FY,
	grandTotal,
	district,
	taluk,
	gram,
	IAgen
}) => {
	const [abstractDataArr, setAbstractDataArr] = useState();
	const [rows, setRows] = useState();

	useEffect(() => {
		setAbstractDataArr(abstractData);
	}, []);

	return (
		<div id="borderedTable" className="mb-5">
			<table className="table table-bordered mb-0">
				<thead>
					<tr>
						<th colspan="1" className="float-right">
							<img src="/leftgandhi.jpg" height="50" width="50" alt="" />
						</th>
						<th colspan="7" className="border p-2 fs-4 text-center">
							<h5>
								{(FY !== 'null') ? `${FY}ನೇ ಸಾಲಿನ ` : ''}ಮಹಾತ್ಮ ಗಾಂಧಿ ನರೇಗಾ ಕ್ರಿಯಾ ಯೋಜನೆಯ ಘೋಷವಾರೆ-2
							</h5>
						</th>
						<th colspan="1">
							<QRCode size={50} value={window.location.href} />
						</th>
						<th colspan="1">
							<img src="/rightgnadhi.jpg" height="50" width="50" alt="" />
						</th>
					</tr>
					{IAgen &&
						<tr>
							<th colspan="2" className=" fs-6 border p-2">
								Implementing Agency:
								<span className="m-2">{IAgen}</span>
							</th>
							<th colspan="2" className=" fs-6 border p-2">
								District:
								<span className="m-2">{district}</span>
							</th>
							<th colspan="3" className=" fs-6 border p-2">
								Taluk:
								<span className="m-2">{taluk}</span>
							</th>
							<th colspan="3" className=" fs-6 border p-2">
								Gram Panchayat:
								<span className="m-2">{gram}</span>
							</th>
						</tr>
					}
					{!IAgen &&
						<tr>
							<th colspan="3" className=" fs-6 border p-2">
								District:
								<span className="m-2">{district}</span>
							</th>
							<th colspan="3" className=" fs-6 border p-2">
								Taluk:
								<span className="m-2">{taluk}</span>
							</th>
							<th colspan="4" className=" fs-6 border p-2">
								Gram Panchayat:
								<span className="m-2">{gram}</span>
							</th>
						</tr>
					}
					<tr>
						<th scope="col">Serial No.</th>
						<th scope="col">Work Category (WC)</th>
						<th scope="col">Work Type</th>
						<th scope="col">No. of Works</th>
						<th scope="col">Estimated Cost (EC) (in lakhs)</th>
						<th scope="col">Wage (W) (in lakhs)</th>
						<th scope="col">Wage % (W%) </th>
						<th scope="col">Material (M) (in lakhs)</th>
						<th scope="col">Material % (M%) </th>
						<th scope="col">Person Days</th>
					</tr>
				</thead>
				<tbody>
					{abstractData?.map((abstract, index) => {
						return (
							<>
								<colgroup>
									<col
										span="2"
										rowspan={
											abstract?.worktypes?.length > 0
												? (abstract?.worktypes?.length).toString()
												: "1"
										}
									/>
									<col rowspan="1" />
								</colgroup>
								<tr>
									<td>{index + 1}</td>
									<td>{abstract.WorkCategory}</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return <tr>{worktype?.name}</tr>;
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return <tr>{worktype?.workStats?.numWorks}</tr>;
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return (
												<tr>
													{worktype?.workStats?.estimatedCost?.toFixed(5)}
												</tr>
											);
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return <tr>{worktype?.workStats?.wage?.toFixed(5)}</tr>;
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return <tr>{worktype?.workStats?.wageP?.toFixed(2)}</tr>;
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return (
												<tr>{worktype?.workStats?.materialCost?.toFixed(5)}</tr>
											);
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return (
												<tr>{worktype?.workStats?.materialP?.toFixed(2)}</tr>
											);
										})}
									</td>

									<td>
										{abstract?.worktypes?.map((worktype, i) => {
											return <tr>{worktype?.workStats?.personDays}</tr>;
										})}
									</td>
								</tr>
								<tr>
									<td>Total</td>
									<td></td>
									<td></td>
									<td>
										<tr>{abstract?.total?.numWorks}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.estimatedCost?.toFixed(5)}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.wage?.toFixed(5)}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.wageP?.toFixed(2)}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.materialCost?.toFixed(5)}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.materialP?.toFixed(2)}</tr>
									</td>
									<td>
										<tr>{abstract?.total?.personDays}</tr>
									</td>
								</tr>
							</>
						);
					})}
					<tr>
						<td>Grand Total: </td>
						<td></td>
						<td></td>
						<td>{grandTotal.numWorks}</td>
						<td>{grandTotal.estimatedCost?.toFixed(5)}</td>
						<td>{grandTotal.wage?.toFixed(5)}</td>
						<td>{grandTotal.wageP?.toFixed(2)}</td>
						<td>{grandTotal.materialCost?.toFixed(5)}</td>
						<td>{grandTotal.materialP?.toFixed(2)}</td>
						<td>{grandTotal.personDays}</td>
					</tr>
				</tbody>
				<tfoot>
					<td colSpan={15}></td>
				</tfoot>
			</table>
		</div>
	);
};
