import { useEffect, useState } from "react";

export const CategoryAbstractTableRow = ({ abstractData, index, abstract }) => {
	// const [worktypes, setWorktypes] = useState([]);

	// useEffect(() => {
	// 	setWorktypes(abstract.worktypes);
	// }, []);

	return (
		<>
			<tr key={index}>
				<td rowSpan={abstract?.worktypes?.length - 1}>{index + 1}</td>
				<td rowSpan={abstract?.worktypes?.length - 1}>
					{abstract.WorkCategory}
				</td>
				{/* <tr rowSpan={abstract.worktypes.length}>
					{abstract.worktypes.map((worktype, index) => {
						return <td>{worktype.name}</td>;
					})}
				</tr> */}
			</tr>
		</>
	);
};
