import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoute from './config/app-route.jsx';
import { BrowserRouter, useRoutes, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
export default function App() {
  let element = useRoutes(AppRoute);

  return element;
}


root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


