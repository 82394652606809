import React, { useEffect, useState } from "react";
import { Card, CardBody } from "./card";
import { TableRow } from "./TableRow";
import QRCode from "react-qr-code";
import "../pdf.css";

export const Table = ({ workListDetails }) => {
	const [workStatus, setWorkStatus] = useState("approve");
	const [designation, setDesignation] = useState("");

	return (
		<div id="borderedTable" className="mb-5">
			<table className="table table-bordered mb-0">
				<thead>
					<tr>
						<th colspan="1" className="float-right">
							<img src="/leftgandhi.jpg" height="50" width="50" alt="" />
						</th>
						<th colspan="10" className="border p-2 fs-4 text-center">
							<h5>
								ಮಹಾತ್ಮ ಗಾಂಧಿ ರಾಷ್ಟ್ರೀಯ ಗ್ರಾಮೀಣ ಉದ್ಯೋಗ ಖಾತರಿ ಯೋಜನೆಯ ಕ್ರಿಯಾ ಯೋಜನೆ.
							</h5>
						</th>
						<th colspan="1">
							<QRCode size={50} value={window.location.href} />
						</th>
						<th colspan="1">
							<img src="/rightgnadhi.jpg" height="50" width="50" alt="" />
						</th>
					</tr>
					<tr>
						<th colspan="4" className=" fs-6 border p-2">
							District:
							<span className="m-2">{workListDetails?.zpuser?.location}</span>
						</th>
						<th colspan="4" className=" fs-6 border p-2">
							Taluk:
							<span className="m-2">{workListDetails?.tpuser?.location}</span>
						</th>
						<th colspan="4" className=" fs-6 border p-2">
							Gram Panchayat:
							<span className="m-2">{workListDetails?.gpuser?.location}</span>
						</th>
						<th scope="row" colspan="1" className="font-weight-bold text-right">
							GS DATE :{" "}
							<span className="font-weight-bold text-right">{new Date(workListDetails?.GS_Date)?.toLocaleDateString("en-GB")} </span>
						</th>
					</tr>
					<tr>
						<th scope="col">Serial No.</th>
						<th scope="col">FY</th>
						<th scope="col">Work Category (WC)</th>
						<th scope="col">Work Type (WT)</th>
						<th scope="col">Work Name (WN)</th>
						<th scope="col">Estimated Cost (EC) (in lakhs)</th>
						<th scope="col">Wage (W) (in lakhs)</th>
						<th scope="col">Material (M) (in lakhs)</th>
						<th scope="col">Wage % (W%) </th>
						<th scope="col">Material % (M%) </th>
						<th scope="col">Person Days</th>
						<th scope="col">Implementing Agency</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{workListDetails?.works?.map((work, index) => {
						return (
							<TableRow
								key={index}
								work={work}
								index={index}
								workListDetails={workListDetails}
							/>
						);
					})}
				</tbody>
				<tbody>
					<tr>
						<th scope="row">Totals:</th>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>{workListDetails?.totalEc?.toFixed(5)} </td>
						<td>{workListDetails?.totalW?.toFixed(5)}</td>
						<td>{workListDetails?.totalM?.toFixed(5)}</td>
						<td>
							{(
								(workListDetails?.totalW / workListDetails?.totalEc) *
								100
							)?.toFixed(2)}
						</td>
						<td>
							{(
								(workListDetails?.totalM / workListDetails?.totalEc) *
								100
							)?.toFixed(2)}
						</td>
						<td>{workListDetails?.totalPD}</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<th scope="row" colspan="13">
							<h6 className="font-weight-bold text-center">
								Total NRM (in lakhs):{" "}
								<span className="font-weight-bold">
									{workListDetails?.totalNRM}
								</span>
							</h6>
						</th>
					</tr>
					<tr>
						<th scope="row" colspan="13">
							<h6 className="font-weight-bold text-center">
								Total NRM % :{" "}
								<span className="font-weight-bold">
									{workListDetails?.NRMP?.toFixed(5)} %
								</span>
							</h6>
						</th>
					</tr>
					<>
						{/* <h4 className="pagebreak my-3">Reports:</h4> */}
						<tr className="pagebreak my-3">
							<td className="fw-bold" colspan="4">
								GP PRESIDENT REPORT:
								<div className="m-2">
									<textarea
										rows="4"
										value={workListDetails?.gp_report}
										cols="60"
										disabled
									></textarea>
								</div>
							</td>
							<td className="fw-bold" colspan="4">
								TP EO REPORT:
								<div className="m-2">
									<textarea
										rows="4"
										cols="60"
										value={workListDetails?.tp_report}
										disabled
									></textarea>
								</div>
							</td>
							<td className="fw-bold" colspan="5">
								ZP CEO REPORT:
								<div className="m-2">
									<textarea
										rows="4"
										cols="80"
										value={workListDetails?.zp_report}
										disabled
									></textarea>
								</div>
							</td>
						</tr>
					</>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="1" className="fw-bold">
							Digitally Signed By:
						</th>
						<td colspan="3">
							<span className="fw-bold">GP PDO:</span>{" "}
							{workListDetails?.entryuser_submitted_at &&
								workListDetails?.entryuser?.name}
							<div className="">
								<span className="fw-bold">Date: </span>
								{workListDetails?.entryuser_submitted_at
									? `${new Date(
										workListDetails?.entryuser_submitted_at
									).toDateString()} at ${new Date(
										workListDetails?.entryuser_submitted_at
									)
										.toTimeString()
										.slice(
											new Date(
												workListDetails?.entryuser_submitted_at
											).toTimeString() - 1,
											-22
										)} `
									: null}
							</div>
						</td>
						<td colspan="3">
							<span className="fw-bold">GP PRESIDENT:</span>{" "}
							{workListDetails?.gpuser_submitted_at &&
								workListDetails?.gpuser?.name}
							<div className="">
								<span className="fw-bold">Date: </span>
								{workListDetails?.gpuser_submitted_at
									? `${new Date(
										workListDetails?.gpuser_submitted_at
									).toDateString()} at ${new Date(
										workListDetails?.gpuser_submitted_at
									)
										.toTimeString()
										.slice(
											new Date(
												workListDetails?.gpuser_submitted_at
											).toTimeString() - 1,
											-22
										)} `
									: null}
							</div>
						</td>
						<td colspan="3">
							<span className="fw-bold">TP EO:</span>{" "}
							{workListDetails?.tpuser_submitted_at &&
								workListDetails?.tpuser?.name}
							<div className="">
								<span className="fw-bold">Date: </span>
								{workListDetails?.tpuser_submitted_at
									? `${new Date(
										workListDetails?.tpuser_submitted_at
									).toDateString()} at ${new Date(
										workListDetails?.tpuser_submitted_at
									)
										.toTimeString()
										.slice(
											new Date(
												workListDetails?.tpuser_submitted_at
											).toTimeString() - 1,
											-22
										)} `
									: null}
							</div>
						</td>
						<td colspan="4">
							<span className="fw-bold">ZP CEO:</span>{" "}
							{workListDetails?.zpuser_submitted_at &&
								workListDetails?.zpuser?.name}
							<div className="">
								<span className="fw-bold">Date: </span>
								{workListDetails?.zpuser_submitted_at
									? `${new Date(
										workListDetails?.zpuser_submitted_at
									).toDateString()} at ${new Date(
										workListDetails?.zpuser_submitted_at
									)
										.toTimeString()
										.slice(
											new Date(
												workListDetails?.zpuser_submitted_at
											).toTimeString() - 1,
											-22
										)} `
									: null}
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
};
