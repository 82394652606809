import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AbstractTable } from "./components/AbstractTable";
import {
	getUserAbstract,
	getUsersAbove,
	getUserAbstractWithIA,
} from "./config/api.service";
import "./pdf.css";

function AbstractPdf() {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const printRef = useRef();
	const [abstractData, setAbstractData] = useState([]);
	const [district, setDistrict] = useState("");
	const [taluk, setTaluk] = useState("");
	const [gram, setGram] = useState("");
	const [grandTotal, setGrandTotal] = useState({});
	const [IAgen, setIAgen] = useState("");
	const [FYgen, setFYgen] = useState("");

	const print = () => {
		printRef.current.style.display = "none";
		window.print();
	};

	useEffect(() => {
		if (abstractData?.length) {
			window.print();
		}
	}, [abstractData]);

	useEffect(() => {
		async function fetchAbstractData() {
			const userId = searchParams.get("user_id");
			const designation = searchParams.get("designation");
			const IA = searchParams.get("IA");
			const FY = searchParams.get("FY");
			setFYgen(FY);
			setIAgen(IA);
			if (userId && designation && !IA) {
				console.log("fire");
				const user = await getUsersAbove({ userId, designation });
				if (designation === "ZP-CEO") {
					setDistrict(user?.location);
				} else if (designation === "TP-EO") {
					setTaluk(user?.location);
					setDistrict(user?.zpuser?.location);
				} else if (designation === "GP-President") {
					setGram(user?.location);
					setTaluk(user?.tpuser?.location);
					setDistrict(user?.tpuser?.zpuser?.location);
				} else if (designation === "Entry-User") {
					setGram(user?.gpuser?.location);
					setTaluk(user?.gpuser?.tpuser?.location);
					setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
				}
				const serverAbstractData = await getUserAbstract(userId, designation, FY);
				setAbstractData(serverAbstractData.userAbstract);
				setGrandTotal(serverAbstractData.grandTotal);
			} else {
				const user = await getUsersAbove({ userId, designation });
				if (designation === "ZP-CEO") {
					setDistrict(user?.location);
				} else if (designation === "TP-EO") {
					setTaluk(user?.location);
					setDistrict(user?.zpuser?.location);
				} else if (designation === "GP-President") {
					setGram(user?.location);
					setTaluk(user?.tpuser?.location);
					setDistrict(user?.tpuser?.zpuser?.location);
				} else if (designation === "Entry-User") {
					setGram(user?.gpuser?.location);
					setTaluk(user?.gpuser?.tpuser?.location);
					setDistrict(user?.gpuser?.tpuser?.zpuser?.location);
				}
				console.log("ice");
				const serverAbstractData = await getUserAbstract(
					userId,
					designation,
					FY,
					IA
				);
				setAbstractData(serverAbstractData.userAbstract);
				setGrandTotal(serverAbstractData.grandTotal);
			}
		}
		fetchAbstractData();
	}, []);

	return (
		<>
			<div
				style={{ fontSize: 10 }}
				className="mailbox border border-danger p-1"
			>
				<div className="mailbox-toolbar">
					<div className="desktop-toggler"></div>
				</div>
				<div className="mailbox-body">
					<div className="mailbox-content">
						{abstractData ? (
							<AbstractTable
								IAgen={IAgen}
								FY={FYgen}
								abstractData={abstractData}
								grandTotal={grandTotal}
								district={district}
								taluk={taluk}
								gram={gram}
							/>
						) : null}

						{/* <table className="table table-bordered mb-0">
							<tfoot>
								<tr>
									<th scope="row" className="col-md-2">
										Digitally Signed By:
									</th>
									<td>
										<span>GP PDO:</span> {abstractData?.entryuser?.name}
										<div>
											Date:{" "}
											{abstractData?.entryuser_submitted_at
												? new Date(
														abstractData?.entryuser_submitted_at
												  ).toDateString()
												: null}
										</div>
									</td>
									<td>
										GP PRESIDENT: {abstractData?.gpuser?.name}
										<div>
											Date:{" "}
											{abstractData?.gpuser_submitted_at
												? new Date(
														abstractData?.gpuser_submitted_at
												  ).toDateString()
												: null}
										</div>
									</td>
									<td>
										TP EO: {abstractData?.tpuser?.name}
										<div>
											Date:{" "}
											{abstractData?.tpuser_submitted_at
												? new Date(
														abstractData?.tpuser_submitted_at
												  ).toDateString()
												: null}
										</div>
									</td>
									<td>
										ZP CEO: {abstractData?.zpuser?.name}
										<div>
											Date:{" "}
											{abstractData?.zpuser_submitted_at
												? new Date(
														abstractData?.zpuser_submitted_at
												  ).toDateString()
												: null}
										</div>
									</td>
								</tr>
							</tfoot>
						</table> */}
					</div>
				</div>
			</div>
		</>
	);
}

export default AbstractPdf;
