import React, { useEffect, useState } from "react";

export const TableRow = ({ work, index, workListDetails }) => {
	// const [workStatus, setWorkStatus] = useState("approve");
	const [workStatusRemarks, setWorkStatusRemarks] = useState("");

	return (
		<>
			<tr key={index}>
				<td>{index + 1}</td>
				<td>{work?.FY}</td>
				<td className="col-md-1">{work?.work_category}</td>
				<td>{work?.work_type}</td>
				<td>{work?.work_name}</td>
				<td>{parseFloat(work?.estimated_cost)?.toFixed(5)}</td>
				<td>{parseFloat(work?.wage)?.toFixed(5)}</td>
				<td>{parseFloat(work?.material)?.toFixed(5)}</td>
				<td>{parseFloat(work?.wage_p)?.toFixed(2)}</td>
				<td>{parseFloat(work?.material_p).toFixed(2)}</td>
				<td>{work?.person_days}</td>
				<td className="col-md-1">{work?.implementing_agency}</td>
				<td>{work?.work_status}</td>
			</tr>
		</>
	);
};
