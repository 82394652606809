import React, { useEffect, useContext, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Card } from "./components/card";
import { getWorkListById } from "./config/api.service";
import { Table } from "./components/Table";
import { useSearchParams } from "react-router-dom";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { Printer } from "react-feather";
import "./pdf.css";

function Pdf() {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const printRef = useRef();
	const [workListData, setWorkListData] = useState({});

	// const styles = StyleSheet.create({
	//     page: {
	//         flexDirection: 'row',
	//         backgroundColor: '#E4E4E4'
	//     },
	//     section: {
	//         margin: 10,
	//         padding: 10,
	//         flexGrow: 1
	//     }
	// });

	const print = () => {
		printRef.current.style.display = "none";
		window.print();
	};

	useEffect(() => {
		async function fetchWorkListData() {
			const params = searchParams.get("worklist_id");
			if (params) {
				const worklistDetails = await getWorkListById(params);
				setWorkListData(worklistDetails);
			}
		}
		fetchWorkListData();
	}, []);

	useEffect(() => {
		if (workListData?.id) {
			window.print();
		}
	}, [workListData]);


	return (
		<>
			<div
				style={{ fontSize: 10 }}
				className="mailbox p-1 print"
			>
				<div className="mailbox-toolbar">
					<div className="desktop-toggler"></div>
				</div>
				<div className="mailbox-body">
					<div className="mailbox-content">
						{workListData ? <Table workListDetails={workListData} /> : null}
					</div>
				</div>
			</div>
		</>
	);
}

export default Pdf;
